import React from "react"

const Squiggle = ({ type }) => {

  return (
    <>
      {type === 'top-1' && (
        <svg className="squiggle squiggle--top-1" viewBox="0 0 1440 326" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="squiggle-top-2" className="squiggle-fill">
              <path d="M1439.856,0 L0,0 L0,7.46 C16.43,5.172 41.095,3.675 69.847,9.612 C78.766,11.454 114.131,19.391 156.765,50.275 C201.987,83.034 203.698,104.168 246.727,144.378 C279.56,175.061 309.32,191.496 336.311,206.403 C447.955,268.064 565.606,267.812 611.278,267.214 C748.985,265.41 860.157,219.054 878.623,211.16 C930.136,189.141 952.101,171.901 1007.266,161.265 C1033.61,156.186 1102.183,143.802 1180.916,169.667 C1198.863,175.563 1246.723,191.783 1293.088,231.3 C1322.35,256.239 1335.133,277.64 1372.726,298.94 C1399.38,314.044 1423.758,321.815 1440,326 L1439.856,0 Z" id="Fill-1" transform="translate(720.000000, 163.000000) scale(-1, -1) translate(-720.000000, -163.000000) "></path>
            </g>
          </g>
        </svg>
      )}
      {type === 'top-2' && (
        <svg className="squiggle squiggle--top-2" viewBox="0 0 1440 489" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Homepage" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M0.144,0 C11.034,9.49684835 25.886,24.6840011 36.688,46.1210875 C52.185,76.8794175 49.801,101.565192 50.899,123.543283 C51.773,141.038131 61.572,226.311989 121.954,278.387675 C146.837,299.847821 172.785,309.083923 191.251,315.469551 C307.632,355.71586 369.136,295.767227 515.614,307.317232 C600.583,314.016821 667.812,344.427489 722.873,368.413505 C788.137,396.843741 815.617,430.893331 894.736,454.198213 C904.079,456.951129 948.328,469.637244 1009.086,474.01406 C1104.399,480.877723 1147.44,460.582068 1223.678,456.019004 C1273.756,453.021307 1347.281,456.264674 1440,488.360438 L1440,489 L0,489 L0.144,0 Z" id="squiggle-top-1" className="squiggle-fill"></path>
            </g>
        </svg>
      )}
      {type === 'bottom-1' && (
        <svg className="squiggle squiggle--bottom-1" viewBox="0 0 1441 268" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="squiggle-bottom-1" className="squiggle-fill">
                  <path d="M1440.01437,0 L1440.01437,3.28639634 C1423.58437,1.38501904 1398.92037,0.140979788 1370.16837,5.07475471 C1361.24837,6.6054964 1325.88337,13.2013144 1283.24937,38.8665839 C1238.02737,66.0900188 1236.31537,83.6528281 1193.28737,117.068205 C1160.45437,142.566439 1130.69437,156.224278 1103.70337,168.612316 C992.059369,219.853936 874.409369,219.644518 828.736369,219.147567 C691.029369,217.648404 579.857369,179.12557 561.392369,172.565486 C509.878369,154.267222 487.912369,139.940411 432.748369,131.101665 C406.404369,126.880907 337.831369,116.589536 259.099369,138.083908 C241.151369,142.983611 193.291369,156.462781 146.925369,189.302259 C117.663369,210.027106 104.881369,227.811798 67.2873691,245.512557 C40.6343691,258.064306 16.2563691,264.522175 0,268 L0,0 L1440.01437,0 Z"></path>
              </g>
          </g>
      </svg>
      )}
    </>
  )
}

export default Squiggle
